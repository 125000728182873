import {t} from '@lingui/macro'
import {FC} from 'react'

import {MainLayout} from '@/components/Layout/MainLayout.tsx'
import {Skeleton} from '@/components/ui/skeleton'

export const UserTableLoading: FC = () => {
  return (
    <MainLayout>
      <div className="flex justify-center flex-grow w-full h-full">
        <div className="w-full max-w-6xl">
          <h1 className="text-2xl font-bold mb-4">{t`User Management`}</h1>
          <div className="flex flex-col space-y-6">
            <div className="space-y-4">
              {Array.from({length: 5}).map((_, index) => (
                <Skeleton key={index} className="w-full h-10 rounded-lg" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
