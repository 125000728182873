import {Trans, t} from '@lingui/macro'
import {FC} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {z} from 'zod'

import {Checkbox} from '@/components/ui/checkbox'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {Input} from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  NEST_PLACE_MESSAGES,
  NestPlaceDto,
} from '@/lib/nest/dtos/NestPlaceDto.ts'
import {NEST_SIZE_MESSAGES, NestSizeDto} from '@/lib/nest/dtos/NestSizeDto'

export const NestDataForm = z.object({
  nestCode: z.string().min(1),
  size: NestSizeDto.optional(),
  species: z.string(),
  floorname: z.string().optional(),
  municipality: z.string().optional(),
  canton: z.string().optional(),
  visitedYearly: z.boolean().optional(),
  place: NestPlaceDto.optional(),
})
export type NestDataForm = z.infer<typeof NestDataForm>

type Props = {
  form: UseFormReturn<NestDataForm>
}

export const NestDataFormStep: FC<Props> = ({form}) => {
  return (
    <>
      <FormField
        control={form.control}
        name="nestCode"
        render={({field}) => (
          <FormItem className="sm:col-span-2">
            <FormLabel>
              <Trans>Nest Code</Trans>
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                onChange={e => field.onChange(e.target.value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="species"
        render={({field}) => (
          <FormItem className="sm:col-span-2">
            <FormLabel>
              <Trans>Species</Trans>
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                onChange={e => field.onChange(e.target.value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="floorname"
        render={({field}) => (
          <FormItem className="sm:col-span-2">
            <FormLabel>
              <Trans>Floorname</Trans>
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                onChange={e => field.onChange(e.target.value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="municipality"
        render={({field}) => (
          <FormItem>
            <FormLabel>
              <Trans>Municipality</Trans>
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                onChange={e => field.onChange(e.target.value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="canton"
        render={({field}) => (
          <FormItem>
            <FormLabel>
              <Trans>Canton</Trans>
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                onChange={e => field.onChange(e.target.value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="size"
        render={({field}) => (
          <FormItem>
            <FormLabel>
              <Trans>Nest size</Trans>
            </FormLabel>
            <Select
              onValueChange={field.onChange}
              defaultValue={field.value || NestSizeDto.enum.NONE}
            >
              <FormControl>
                <SelectTrigger data-testid="Select.size">
                  <SelectValue placeholder={t`Select nest size`} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {Object.values(NestSizeDto.enum).map(size => (
                  <SelectItem
                    data-testid={`Select.size.${size}`}
                    key={size}
                    value={size}
                  >
                    {t({
                      id: NEST_SIZE_MESSAGES[size].id,
                      message: NEST_SIZE_MESSAGES[size].message,
                    })}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="place"
        render={({field}) => (
          <FormItem>
            <FormLabel>
              <Trans>Nest Location</Trans>
            </FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger data-testid="Select.place">
                  <SelectValue placeholder={t`Select nest location`} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {Object.values(NestPlaceDto.enum).map(location => (
                  <SelectItem
                    key={location}
                    value={location}
                    data-testid={`Select.place.${location}`}
                  >
                    {t({
                      id: NEST_PLACE_MESSAGES[location].id,
                      message: NEST_PLACE_MESSAGES[location].message,
                    })}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="visitedYearly"
        render={({field}) => (
          <FormItem className="flex flex-row items-center gap-x-2">
            <FormControl>
              <Checkbox
                className="h-5 w-5"
                checked={field.value}
                name={field.name}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabel className="!my-0">
              <Trans>should be visited yearly</Trans>
            </FormLabel>
          </FormItem>
        )}
      />
    </>
  )
}
