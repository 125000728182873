import {z} from 'zod'

import {ApiPaginationOptionsDto} from '@/lib/api/dtos/ApiPaginationOptionsDto'

export const NestListOptionsDto = ApiPaginationOptionsDto.extend({
  nestCode: z.string().optional(),
  species: z.string().optional(),
  canton: z.string().optional(),
  municipality: z.string().optional(),
  user: z.string().optional(),
  place: z.string().optional(),
  minLat: z.number().optional(),
  maxLat: z.number().optional(),
  minLong: z.number().optional(),
  maxLong: z.number().optional(),
  minAlt: z.number().optional(),
  maxAlt: z.number().optional(),
  minUpdated: z.string().optional(),
  maxUpdated: z.string().optional(),
  minCreated: z.string().optional(),
  maxCreated: z.string().optional(),
  visitedYearly: z.boolean().optional(),
})

export type NestListOptionsDto = z.infer<typeof NestListOptionsDto>
