import {Trans} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {FC} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {Button} from '@/components/ui/button'
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {Input} from '@/components/ui/input'
import {LoginDto} from '@/lib/auth/dtos/LoginDto'

type Props = {
  form: UseFormReturn<LoginDto>
  onSubmit: (credentials: LoginDto) => void
}

export const LoginForm: FC<Props> = ({form, onSubmit}) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <Card>
        <CardHeader>
          <CardTitle>
            <Trans>Login Form</Trans>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>E-Mail</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={e => field.onChange(e.target.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Password</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="password"
                        onChange={e => field.onChange(e.target.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" data-testid="LoginForm.submit">
                <Trans>Login</Trans>
              </Button>
            </form>
          </Form>
          <div className="mt-4 text-center">
            <Trans>Don't have an account?</Trans>
            <Link className="ml-1" to="/auth/register">
              <Trans>Register</Trans>
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
