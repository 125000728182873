import {msg} from '@lingui/macro'
import {z} from 'zod'

export const NestSizeDto = z.enum([
  'NONE',
  'VERY_SMALL',
  'SMALL',
  'MEDIUM',
  'LARGE',
  'VERY_LARGE',
])
export type NestSizeDto = z.infer<typeof NestSizeDto>

export const NEST_SIZE_MESSAGES = {
  [NestSizeDto.enum.NONE]: msg`None`,
  [NestSizeDto.enum.VERY_SMALL]: msg`Very Small`,
  [NestSizeDto.enum.SMALL]: msg`Small`,
  [NestSizeDto.enum.MEDIUM]: msg`Medium`,
  [NestSizeDto.enum.LARGE]: msg`Large`,
  [NestSizeDto.enum.VERY_LARGE]: msg`Very Large`,
}
