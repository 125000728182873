import {Trans} from '@lingui/macro'
import {FC} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {Back} from '@/components/ui/back'
import {Button} from '@/components/ui/button.tsx'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx'
import {Form} from '@/components/ui/form.tsx'
import {ObservationCreateDto} from '@/lib/observation/dtos/ObservationCreateDto.ts'

import {ObservationDataFormStep} from './ObservationDataFormStep'

type Props = {
  form: UseFormReturn<ObservationCreateDto>
  onSubmit: (observation: ObservationCreateDto) => void
}

export const ObservationForm: FC<Props> = ({form, onSubmit}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <Back to=".." search={{page: 0}} />

        <div className="text-xl font-semibold tracking-tight shrink-0 whitespace-nowrap sm:grow-0">
          <Trans>Create new Observation</Trans>
        </div>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <Card>
            <CardHeader>
              <CardTitle>
                <Trans>Observation Form</Trans>
              </CardTitle>
            </CardHeader>

            <CardContent>
              <ObservationDataFormStep form={form} />
            </CardContent>
            <CardFooter>
              <Button className="w-auto">
                <Trans>Upload Picture</Trans>
              </Button>
            </CardFooter>
          </Card>
          <div className="flex justify-end">
            <Button type="submit">
              <Trans>Submit</Trans>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
