import {FC} from 'react'

import {UserOverviewDto} from '@/lib/user/dtos/UserOverviewDto.ts'

import {AVATAR_BACKGROUNDS} from './colors.ts'

type Props = {
  user: UserOverviewDto
}

const getBackgroundColors = (id: any) => {
  const stringUniqueHash = [...id].reduce(
    (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc),
    0
  )

  const colorIndex1 = Math.abs(stringUniqueHash) % AVATAR_BACKGROUNDS.length
  const color2 = lightenHexColor(AVATAR_BACKGROUNDS[colorIndex1], 30)

  return [AVATAR_BACKGROUNDS[colorIndex1], color2]
}

const lightenHexColor = (hex: string, percent: number) => {
  hex = hex.replace('#', '')
  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)))
  g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)))
  b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)))

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

const UserAvatar: FC<Props> = ({user}) => {
  const firstLetter = user.firstname.charAt(0).toUpperCase()
  const [color1, color2] = getBackgroundColors(user.id)
  return (
    <div
      style={{
        background: `linear-gradient(to right, ${color1}, ${color2})`,
      }}
      className="rounded-full flex items-center justify-center text-white font-bold md:w-14 md:h-14 sm:w-12 sm:h-12 w-10 h-10"
    >
      <span className="text-white text-2xl font-bold">{firstLetter}</span>
    </div>
  )
}

export default UserAvatar
