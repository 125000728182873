import {useSuspenseQuery} from '@tanstack/react-query'
import {createFileRoute} from '@tanstack/react-router'

import {NestDetail} from '@/components/Nest/NestDetail'
import {NestDetailError} from '@/components/Nest/NestDetail/error'
import {NestDetailLoading} from '@/components/Nest/NestDetail/loading'
import {nestQueryOptions, observationsQueryOptions} from '@/lib/nest/query'

export const Route = createFileRoute('/nests/overview/all/$nestId/')({
  loader: ({context: {queryClient}, params: {nestId}}) => {
    queryClient.ensureQueryData(nestQueryOptions(nestId)),
      queryClient.ensureQueryData(observationsQueryOptions(nestId))
  },
  pendingComponent: NestDetailLoading,
  errorComponent: NestDetailError,
  component: NestDetailComponent,
  pendingMs: 0,
})

function NestDetailComponent() {
  const {nestId} = Route.useParams()
  const nestQuery = useSuspenseQuery(nestQueryOptions(nestId))
  const observationsQuery = useSuspenseQuery(observationsQueryOptions(nestId))

  return (
    <NestDetail
      from="/nests/overview/all"
      nest={nestQuery.data.data}
      observations={observationsQuery.data.data}
      editable={true}
    />
  )
}
