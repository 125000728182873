import {t} from '@lingui/macro'
import {useMutation, useQuery} from '@tanstack/react-query'
import {createFileRoute} from '@tanstack/react-router'
import {toast} from 'sonner'

import {MainLayout} from '@/components/Layout/MainLayout'
import {UserTable} from '@/components/Users/UsersTable'
import {UserTableError} from '@/components/Users/UsersTable/error.tsx'
import {UserTableLoading} from '@/components/Users/UsersTable/loading.tsx'
import {queryClient} from '@/lib/api/queryClient.ts'
import {UserOverviewDto} from '@/lib/user/dtos/UserOverviewDto.ts'
import {userKeys, usersQueryOptions} from '@/lib/user/query.ts'
import {userService} from '@/lib/user/userService.ts'

export const Route = createFileRoute('/users/')({
  component: UserManagementComponent,
  loader: ({context: {queryClient}}) =>
    queryClient.ensureQueryData(usersQueryOptions()),
  pendingComponent: UserTableLoading,
  errorComponent: UserTableError,
})

function UserManagementComponent() {
  const {data: users} = useQuery(usersQueryOptions())

  const {mutate} = useMutation({
    mutationFn: (newUser: UserOverviewDto) => userService.update(newUser),
    onSettled: () => queryClient.invalidateQueries({queryKey: userKeys.all()}),
    onSuccess: () => {
      toast.success(t`User role updated successfully`)
    },
    onError: () => {
      toast.error(t`Failed to update user role`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
    },
  })

  return (
    <MainLayout>
      <div className="flex justify-center flex-grow w-full h-full">
        <div className="w-full max-w-6xl">
          <h1 className="text-2xl font-bold mb-4">{t`User Management`}</h1>
          <UserTable users={users?.data || []} onChange={mutate} />
        </div>
      </div>
    </MainLayout>
  )
}
