import {Trans, t} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {format} from 'date-fns'
import {EditIcon, PlusIcon} from 'lucide-react'
import {FC} from 'react'

import {ImageCarousel} from '@/components/Nest/NestDetail/ImageCarousel.tsx'
import {ObservationTable} from '@/components/Observation/ObservationTable'
import {Back} from '@/components/ui/back'
import {Badge} from '@/components/ui/badge.tsx'
import {Button} from '@/components/ui/button'
import {Card, CardContent} from '@/components/ui/card'
import {NestDetailDto as Data} from '@/lib/nest/dtos/NestDetailDto'
import {NEST_PLACE_MESSAGES} from '@/lib/nest/dtos/NestPlaceDto.ts'
import {NEST_SIZE_MESSAGES} from '@/lib/nest/dtos/NestSizeDto'
import {ObservationItemDto} from '@/lib/observation/dtos/ObservationItemDto.ts'

import {NestFavorite} from '../NestFavorite'
import {NestSyncStatus} from '../NestSyncStatus'

type FromPaths = '/nests/overview/all' | '/nests/overview/favorites'
type Props = {
  nest: Data
  observations?: ObservationItemDto[]
  from: FromPaths
  editable?: boolean
}

export const NestDetail: FC<Props> = ({
  nest,
  from,
  observations,
  editable = false,
}) => {
  return (
    <div className="flex flex-col gap-4" data-testid="NestDetail">
      <div className="flex items-center gap-4">
        <Back to={from} search={{page: 0}} />

        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <h1
            data-testid="NestDetail.nestCode"
            className="flex-1 text-xl font-semibold tracking-tight shrink-0 whitespace-nowrap sm:grow-0"
          >
            {nest.nestCode}
          </h1>
          <Badge data-testid="NestDetail.species">{nest.species}</Badge>
          {nest.visitedYearly && (
            <Badge variant="outline" data-testid="NestDetail.visitedYearly">
              <Trans>is visited yearly</Trans>
            </Badge>
          )}
        </div>

        <span className="flex-1"></span>

        {editable && (
          <Button variant="outline" asChild>
            <Link
              data-testid="NestDetail.editData"
              to="/nests/overview/all/$nestId/edit/data"
              params={{nestId: nest.id}}
              className="flex gap-2"
            >
              <EditIcon className="w-4 h-4" />
              <Trans>Edit</Trans>
            </Link>
          </Button>
        )}
        <NestFavorite nest={nest} />
        <NestSyncStatus id={nest.id} />
      </div>
      <Card
        style={{viewTransitionName: `nestCard-${nest.id}`}}
        className="pt-6"
      >
        <CardContent className="relative flex flex-col w-full gap-4">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <div className="font-semibold flex gap-4 items-center pb-1">
                <Trans>Coordinates</Trans>
                {editable && (
                  <Button variant="outline" asChild>
                    <Link
                      data-testid="NestDetail.editLocation"
                      to="/nests/overview/all/$nestId/edit/location"
                      params={{nestId: nest.id}}
                      className="flex gap-2"
                    >
                      <EditIcon className="w-4 h-4" />
                      <Trans>Edit</Trans>
                    </Link>
                  </Button>
                )}
              </div>
              <div className="flex gap-x-2">
                <span data-testid="NestDetail.latitude">
                  {nest.latitude.toFixed(6)}
                </span>
                <span>/</span>
                <span data-testid="NestDetail.longitude">
                  {nest.longitude.toFixed(6)}
                </span>
              </div>
            </div>
            <div>
              <div className="font-semibold">
                <Trans>Height</Trans>
              </div>
              <div className="flex gap-x-1">
                <span data-testid="NestDetail.height">{nest.height}</span>
                <span>
                  <Trans>MASL</Trans>
                </span>
              </div>
            </div>
            <div>
              <div className="font-semibold">
                <Trans>Floorname</Trans>
              </div>
              <div>{nest.floorname}</div>
            </div>
            <div>
              <div className="font-semibold">
                <Trans>Municipality</Trans>
              </div>
              <div className="flex gap-x-2">
                <span data-testid="NestDetail.municipality">
                  {nest.municipality},
                </span>
                <span data-testid="NestDetail.canton">{nest.canton}</span>
              </div>
            </div>
            <div>
              <div className="font-semibold">
                <Trans>Size</Trans>
              </div>
              <div data-testid="NestDetail.size">
                {t({
                  id: NEST_SIZE_MESSAGES[nest.size || 'NONE'].id,
                  message: NEST_SIZE_MESSAGES[nest.size || 'NONE'].message,
                })}
              </div>
            </div>
            <div>
              <div className="font-semibold">
                <Trans>Nest Place</Trans>
              </div>
              <div data-testid="NestDetail.place">
                {t({
                  id: NEST_PLACE_MESSAGES[nest.place || 'NONE'].id,
                  message: NEST_PLACE_MESSAGES[nest.place || 'NONE'].message,
                })}
              </div>
            </div>
            <div>
              <div className="font-semibold">
                <Trans>Initial Recording</Trans>
              </div>
              {nest.user && (
                <div className="flex gap-x-2">
                  <span data-testid="NestDetail.user">
                    {nest.user
                      .split(' ')
                      .map(n => n.charAt(0) + n.substring(1).toLowerCase())
                      .join(' ')}
                    ,
                  </span>
                  <span data-testid="NestDetail.createdAt">
                    {format(new Date(nest.createdAt), 'dd.MM.yy')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <ImageCarousel />
          <div>
            <div className="text-lg font-semibold">
              <Trans>Observations</Trans>
            </div>
            <div className="flex flex-col gap-4">
              <ObservationTable key={nest.id} observations={observations} />
              <Link
                search={{observationPage: 0}}
                to={'./newObservation'}
                from={'/nests/overview/all/$nestId'}
              >
                <Button className="flex w-auto gap-2 text-background">
                  <PlusIcon className="w-4 h-4" />
                  <Trans>Add Observation</Trans>
                </Button>
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
