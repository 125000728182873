import {synchronize} from '@nozbe/watermelondb/sync'

import {syncService} from '@/lib/sync/syncService'

import {queryClient} from '../api/queryClient'

import {database} from './'

export async function sync() {
  await synchronize({
    database,
    pullChanges: async ({lastPulledAt, schemaVersion, migration}) => {
      const {timestamp, data: changes} = await syncService.pull({
        last_pulled_at: lastPulledAt,
        schema_version: schemaVersion,
        migration: encodeURIComponent(JSON.stringify(migration)),
      })

      // We first update the local database with the changes
      // and then we invalidate the local query to trigger a re-fetch
      requestAnimationFrame(() => {
        void queryClient.invalidateQueries({
          queryKey: ['local'],
        })
      })

      return {changes, timestamp}
    },
    // onDidPullChanges: async () => {
    //   toast.success(t`Changes synchronised successfully`)
    // },
    pushChanges: async ({changes}) => {
      await syncService.push(changes)
    },
    migrationsEnabledAtVersion: 1,
  })
}
