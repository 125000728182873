import {Trans} from '@lingui/macro'
import {Icon} from 'leaflet'
import {FC, useEffect, useState} from 'react'
import {Marker, Popup} from 'react-leaflet'

import currentLocationMarker from '@/assets/map/icons/current-location-marker.png'

export const CurrentLocationMarker = () => {
  const [position, setPosition] = useState({latitude: 0, longitude: 0})

  useEffect(() => {
    const success: PositionCallback = pos => {
      const crd = pos.coords
      setPosition({latitude: crd.latitude, longitude: crd.longitude})
    }

    const error: PositionErrorCallback = err => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error(`Current Location: ERROR(${err.code}): ${err.message}`)
    }

    if (!('geolocation' in navigator)) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error('Current Location: Geolocation is not supported')
      return
    }

    const id = navigator.geolocation.watchPosition(success, error, {
      enableHighAccuracy: true,
      timeout: 60_000,
      maximumAge: 0,
    })

    return () => {
      if (id) {
        navigator.geolocation.clearWatch(id)
      }
    }
  }, [])

  return (
    <CLMarker position={[position.latitude, position.longitude]}>
      <Popup>
        <Trans>Current Location</Trans>
      </Popup>
    </CLMarker>
  )
}

type CLMarkerProps = React.ComponentProps<typeof Marker>
const CLMarker: FC<CLMarkerProps> = props => {
  return (
    <Marker
      {...props}
      icon={
        new Icon({
          iconUrl: currentLocationMarker,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -40],
        })
      }
    />
  )
}
