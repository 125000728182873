import {FC} from 'react'

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
  PaginationEllipsis,
} from '../ui/pagination'

type Props = {
  onPageChange?: (page: number) => void
  next: string | null
  previous: string | null
  page: number
  totalPages: number
}

const MAX_PAGES = 5
const MIDDLE = Math.floor(MAX_PAGES / 2) + 1

export const ApiPagination: FC<Props> = ({
  next,
  page,
  previous,
  totalPages,
}) => {
  const start = Math.max(1, page - 1)
  const pagesLength = Math.min(totalPages, MAX_PAGES, totalPages - start + 1)
  const pages = Array.from({length: pagesLength}, (_, i) => i + start)

  const shouldShowStartEllipsis = totalPages > MAX_PAGES && page > MIDDLE
  const shouldShowEndEllipsis =
    totalPages > MAX_PAGES && page < totalPages - MIDDLE

  return (
    <Pagination className="vt-name-[pagination]">
      <PaginationContent>
        {previous && (
          <PaginationItem>
            <PaginationPrevious
              data-testid="ApiPagination.previous"
              search={(prev: any) => ({...prev, page: page - 1})}
            />
          </PaginationItem>
        )}
        {shouldShowStartEllipsis && (
          <>
            <PaginationItem>
              <PaginationLink
                data-testid="ApiPaginatin.link.1"
                search={(prev: any) => ({...prev, page: 0})}
                isActive={page === 0}
              >
                1
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationEllipsis data-testid="ApiPaginatin.startEllipsis" />
            </PaginationItem>
          </>
        )}
        {pages.map(p => (
          <PaginationItem key={p}>
            <PaginationLink
              data-testid={`ApiPaginatin.link.${p}`}
              style={{
                viewTransitionName: `pagination-page-${p}`,
              }}
              search={(prev: any) => ({...prev, page: p - 1})}
              isActive={p === page + 1}
            >
              {p}
            </PaginationLink>
          </PaginationItem>
        ))}
        {shouldShowEndEllipsis && (
          <>
            <PaginationItem>
              <PaginationEllipsis data-testid="ApiPaginatin.endEllipsis" />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                data-testid={`ApiPaginatin.link.${totalPages}`}
                search={(prev: any) => ({...prev, page: totalPages - 1})}
                isActive={page === totalPages}
              >
                {totalPages}
              </PaginationLink>
            </PaginationItem>
          </>
        )}
        {next && (
          <PaginationItem>
            <PaginationNext
              search={(prev: any) => ({...prev, page: page + 1})}
            />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )
}
