import {t, Trans} from '@lingui/macro'
import {FC, useState} from 'react'

import {Button} from '@/components/ui/button'
import {Input} from '@/components/ui/input'
import {NestListOptionsDto} from '@/lib/nest/dtos/NestListOptionsDto'

type Props = {
  filters: NestListOptionsDto
  onFilterChange: (filters: NestListOptionsDto) => void
  onResetFilters: () => void
}

export const NestFilter: FC<Props> = ({
  filters,
  onFilterChange,
  onResetFilters,
}) => {
  const [showFilters, setShowFilters] = useState(false)

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Input
          type="text"
          data-testid="Input.filter.nestCode"
          value={filters.nestCode || ''}
          onChange={e => onFilterChange({...filters, nestCode: e.target.value})}
          placeholder={t`Search by nest code...`}
          className="w-full"
        />
      </div>
      <div className="flex gap-2">
        <Button
          type="button"
          data-testid="Input.filter.showFilters"
          variant="outline"
          onClick={() => setShowFilters(prev => !prev)}
        >
          {showFilters ? t`Hide Filters` : t`Show Filters`}
        </Button>
        <Button
          type="button"
          data-testid="Input.filter.resetFilters"
          variant="outline"
          onClick={onResetFilters}
        >
          {t`Reset Filters`}
        </Button>
      </div>
      {showFilters && (
        <div className="flex flex-wrap gap-4">
          <div className="flex flex-col max-w-xs">
            <label>
              <Trans>Species:</Trans>
            </label>
            <Input
              type="text"
              data-testid="Input.filter.species"
              value={filters.species || ''}
              onChange={e =>
                onFilterChange({...filters, species: e.target.value})
              }
              placeholder={t`Species...`}
            />
          </div>
          <div className="flex flex-col max-w-xs">
            <label>
              <Trans>Canton:</Trans>
            </label>
            <Input
              type="text"
              data-testid="Input.filter.canton"
              value={filters.canton || ''}
              onChange={e =>
                onFilterChange({...filters, canton: e.target.value})
              }
              placeholder={t`Canton...`}
            />
          </div>
          <div className="flex flex-col max-w-xs">
            <label>
              <Trans>Municipality:</Trans>
            </label>
            <Input
              type="text"
              data-testid="Input.filter.municipality"
              value={filters.municipality || ''}
              onChange={e =>
                onFilterChange({...filters, municipality: e.target.value})
              }
              placeholder={t`Municipality...`}
            />
          </div>
          <div className="flex flex-col max-w-xs">
            <label>
              <Trans>User:</Trans>
            </label>
            <Input
              type="text"
              data-testid="Input.filter.user"
              value={filters.user || ''}
              onChange={e => onFilterChange({...filters, user: e.target.value})}
              placeholder={t`User...`}
            />
          </div>
          <div className="flex items-center">
            <label className="mr-2">
              <Trans>Visited yearly</Trans>
            </label>
            <Input
              type="checkbox"
              data-testid="Input.filter.visitedYearly"
              checked={filters.visitedYearly || false}
              onChange={e =>
                onFilterChange({...filters, visitedYearly: e.target.checked})
              }
              className="w-4 h-4"
            />
          </div>
          <div className="flex flex-col w-24">
            <label>
              <Trans>Min altitude</Trans>
            </label>
            <Input
              type="number"
              data-testid="Input.filter.minAlt"
              value={filters.minAlt || ''}
              onChange={e =>
                onFilterChange({
                  ...filters,
                  minAlt: e.target.value ? +e.target.value : undefined,
                })
              }
            />
          </div>
          <div className="flex flex-col w-24">
            <label>
              <Trans>Max altitude</Trans>
            </label>
            <Input
              type="number"
              data-testid="Input.filter.maxAlt"
              value={filters.maxAlt || ''}
              onChange={e =>
                onFilterChange({
                  ...filters,
                  maxAlt: e.target.value ? +e.target.value : undefined,
                })
              }
            />
          </div>
          <div className="flex flex-col w-40">
            <label>
              <Trans>Created from</Trans>
            </label>
            <Input
              type="date"
              data-testid="Input.filter.minCreated"
              value={filters.minCreated || ''}
              onChange={e =>
                onFilterChange({
                  ...filters,
                  minCreated: e.target.value || undefined,
                })
              }
            />
          </div>
          <div className="flex flex-col w-40">
            <label>
              <Trans>Created until</Trans>
            </label>
            <Input
              type="date"
              data-testid="Input.filter.maxCreated"
              value={filters.maxCreated || ''}
              onChange={e =>
                onFilterChange({
                  ...filters,
                  maxCreated: e.target.value || undefined,
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}
