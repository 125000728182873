import {msg} from '@lingui/macro'
import {z} from 'zod'

export const UserRoleDto = z.enum([
  'ADMIN',
  'CHIEF',
  'WARDEN',
  'HIKER',
  'CLERK',
  'PUBLIC',
])
export type UserRoleDto = z.infer<typeof UserRoleDto>

export const USER_ROLE_MESSAGES = {
  [UserRoleDto.enum.ADMIN]: msg`Admin`,
  [UserRoleDto.enum.CHIEF]: msg`Chief`,
  [UserRoleDto.enum.WARDEN]: msg`Warden`,
  [UserRoleDto.enum.HIKER]: msg`Hiker`,
  [UserRoleDto.enum.CLERK]: msg`Clerk`,
  [UserRoleDto.enum.PUBLIC]: msg`Public`,
}
