import {SyncStatus} from '@nozbe/watermelondb/Model'
import {useEffect, useState} from 'react'

import {nestLocalService} from '../../../lib/nest/nestLocalService'

export const useNestSyncStatus = (id: string) => {
  const detail$ = nestLocalService.detail$(id)
  const [syncStatus, setSyncStatus] = useState<SyncStatus>()

  useEffect(() => {
    const subscription = detail$.subscribe({
      next: nest => setSyncStatus(nest.syncStatus),
      error: () => setSyncStatus(undefined),
    })

    return () => {
      subscription.unsubscribe()
    }
  })

  return syncStatus
}
