import {zodResolver} from '@hookform/resolvers/zod'
import {t} from '@lingui/macro'
import {useMutation} from '@tanstack/react-query'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'

import {RegistrationForm} from '@/components/Auth/RegistrationForm'
import {MainLayout} from '@/components/Layout/MainLayout'
import {authService} from '@/lib/auth/authService'
import {RegistrationDto} from '@/lib/auth/dtos/RegistrationDto'

export const Route = createFileRoute('/auth/register/')({
  component: RegistrationComponent,
})

function RegistrationComponent() {
  const form = useForm<RegistrationDto>({
    resolver: zodResolver(RegistrationDto),
  })

  const navigation = useNavigate({from: '/auth/register'})
  const {mutate} = useMutation({
    mutationFn: (registrationData: RegistrationDto) =>
      authService.register(registrationData),
    onSuccess: data => {
      const email = data?.data?.email
      navigation({
        to: '/auth/register/verify',
        search: {email: encodeURIComponent(email)},
      }).then()
    },
    onError: () => {
      toast.error(t`Registration failed`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
    },
  })

  const onSubmit = (registrationData: RegistrationDto) => {
    mutate(registrationData)
  }

  return (
    <MainLayout>
      <div className="flex items-center justify-center flex-grow w-full h-full">
        <div className="w-full max-w-96">
          <RegistrationForm form={form} onSubmit={onSubmit} />
        </div>
      </div>
    </MainLayout>
  )
}
