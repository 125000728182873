import {useSuspenseQuery} from '@tanstack/react-query'
import {createFileRoute} from '@tanstack/react-router'

import {ObservationDetail} from '@/components/Observation/ObservationDetail'
import {ObservationDetailError} from '@/components/Observation/ObservationDetail/error.tsx'
import {observationQueryOptions} from '@/lib/nest/query.ts'

export const Route = createFileRoute(
  '/nests/overview/all/$nestId/observation/$observationId/'
)({
  errorComponent: ObservationDetailError,
  component: ObservationDetailComponent,
})

function ObservationDetailComponent() {
  const {nestId} = Route.useParams()
  const {observationId} = Route.useParams()
  const observationQuery = useSuspenseQuery(
    observationQueryOptions(observationId)
  )

  return (
    <ObservationDetail
      from={'/nests/overview/all/$nestId'}
      nestId={nestId}
      observation={observationQuery.data.data}
    />
  )
}
