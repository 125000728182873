import {useState} from 'react'
import {useMap, useMapEvent} from 'react-leaflet'

export const useMapBounds = () => {
  const map = useMap()

  const [bounds, setBounds] = useState({
    ne: map.getBounds().getNorthEast(),
    sw: map.getBounds().getSouthWest(),
  })

  useMapEvent('moveend', () => {
    const bounds = map.getBounds()
    setBounds({
      ne: bounds.getNorthEast(),
      sw: bounds.getSouthWest(),
    })
  })

  return bounds
}
