import {keepPreviousData, useQuery} from '@tanstack/react-query'
import {Link} from '@tanstack/react-router'
import {FC} from 'react'
import {Marker, Popup} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import {useMapBounds} from '@/components/Map/hooks/useMapBounds'
import {pinIcon} from '@/components/Map/icons/pin'
import {nestsQueryOptions} from '@/lib/nest/queryLocal'

export const FavoritesNestMarkers: FC = () => {
  const bounds = useMapBounds()

  const nestsQuery = useQuery({
    ...nestsQueryOptions({
      page: 0,
      size: 9999,
      minLat: bounds.sw.lat,
      maxLat: bounds.ne.lat,
      minLong: bounds.sw.lng,
      maxLong: bounds.ne.lng,
    }),
    placeholderData: keepPreviousData,
  })

  // useEffect(() => {
  //   if (!nestsQuery.data) {
  //     return
  //   }
  //   map.fitBounds(
  //     nestsQuery.data.data.map(nest => [nest.latitude, nest.longitude]),
  //     {padding: [50, 50]}
  //   )
  // }, [map, nestsQuery.data])

  if (!nestsQuery.data) {
    return null
  }

  const {data: nests} = nestsQuery.data

  return (
    <MarkerClusterGroup chunkedLoading>
      {nests.map(nest => (
        <Marker
          key={`${nest.id}-favorites-marker`}
          icon={pinIcon('#C60000')}
          position={[nest.latitude, nest.longitude]}
        >
          <Popup>
            <Link
              to="/nests/overview/favorites/$nestId"
              params={{nestId: nest.id}}
            >
              {nest.nestCode}
            </Link>
          </Popup>
        </Marker>
      ))}
    </MarkerClusterGroup>
  )
}
