import {Trans} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {ChevronLeft} from 'lucide-react'
import {ComponentProps} from 'react'

import {cn} from '@/lib/utils'

import {Button} from './button'

type Props = ComponentProps<typeof Link>

export const Back: React.FC<Props> = props => {
  return (
    <Link
      data-testid="Back"
      {...props}
      className={cn('vt-name-[back]', props.className)}
    >
      <Button variant="outline" size="icon" className="h-7 w-7">
        <ChevronLeft className="w-4 h-4" />
        <span className="sr-only">
          <Trans>Back</Trans>
        </span>
      </Button>
    </Link>
  )
}
