import {api} from '@/lib/api/api.ts'
import {ApiResponseDto} from '@/lib/api/dtos/ApiResponseDto.ts'
import {UserOverviewDto} from '@/lib/user/dtos/UserOverviewDto.ts'

export function all() {
  return api.get('api/user').json<ApiResponseDto<UserOverviewDto[]>>()
}

export function update(json: UserOverviewDto) {
  return api
    .put('api/user/' + json.id, {json})
    .json<ApiResponseDto<UserOverviewDto>>()
}

export const userService = {
  all,
  update,
}
