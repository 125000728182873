import {ReactNode} from '@tanstack/react-router'
import {FC, PropsWithChildren, useContext} from 'react'

import {cn} from '@/lib/utils'

import {StepperContext} from '.'

type Props = {
  step: number
  icon: ReactNode
}

export const StepperItem: FC<PropsWithChildren<Props>> = ({
  children,
  step,
  icon,
}) => {
  const context = useContext(StepperContext)

  if (!context) {
    throw new Error('StepperItem must be used within a Stepper')
  }

  const {activeStep, totalSteps} = context

  const isActiveStep = activeStep === step
  const isPastStep = activeStep > step
  const isLastStep = totalSteps - 1 === step

  return (
    <div
      className={cn('flex gap-4', {
        'flex-grow': !isLastStep,
      })}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={cn(
            'w-14 h-14 flex items-center justify-center rounded-full ',
            {
              'bg-gray-200': isPastStep,
              'bg-black text-white vt-name-[activeStepper]': isActiveStep,
            }
          )}
        >
          {icon}
        </div>
        {children}
        {/* Divider */}
      </div>
      {!isLastStep && (
        <div
          className={cn('flex-grow h-[1px] mt-8 bg-gray-200', {
            'bg-black': isPastStep,
          })}
        ></div>
      )}
    </div>
  )
}
