import {Q} from '@nozbe/watermelondb'

import {NestCreateDto} from './dtos/NestCreateDto'
import {NestListOptionsDto} from './dtos/NestListOptionsDto'
import {NestModel} from './models/NestModel'
import {ApiPaginationResponse} from '../api/dtos/ApiPaginationResponseDto'
import {database} from '../db'

async function list(
  options: NestListOptionsDto
): Promise<ApiPaginationResponse<NestModel[]>> {
  const clauses: Q.Clause[] = []

  if (options.nestCode) {
    clauses.push(
      Q.where('nestCode', Q.like(`%${Q.sanitizeLikeString(options.nestCode)}%`))
    )
  }

  if (options.species) {
    clauses.push(
      Q.where('species', Q.like(`%${Q.sanitizeLikeString(options.species)}%`))
    )
  }

  if (options.canton) {
    clauses.push(
      Q.where('canton', Q.like(`%${Q.sanitizeLikeString(options.canton)}%`))
    )
  }

  if (options.municipality) {
    clauses.push(
      Q.where(
        'municipality',
        Q.like(`%${Q.sanitizeLikeString(options.municipality)}%`)
      )
    )
  }

  if (options.user) {
    clauses.push(
      Q.where('user', Q.like(`%${Q.sanitizeLikeString(options.user)}%`))
    )
  }

  if (options.place) {
    clauses.push(
      Q.where('place', Q.like(`%${Q.sanitizeLikeString(options.place)}%`))
    )
  }

  const coordinatesWhere: Q.Where[] = []
  if (options.minLat && options.maxLat) {
    coordinatesWhere.push(
      Q.where('latitude', Q.between(options.minLat, options.maxLat))
    )
  }

  if (options.minLong && options.maxLong) {
    coordinatesWhere.push(
      Q.where('longitude', Q.between(options.minLong, options.maxLong))
    )
  }

  if (coordinatesWhere.length) {
    clauses.push(Q.and(...coordinatesWhere))
  }

  const altitudeWhere: Q.Where[] = []
  if (options.minAlt) {
    altitudeWhere.push(Q.where('height', Q.gte(options.minAlt)))
  }
  if (options.maxAlt) {
    altitudeWhere.push(Q.where('height', Q.lte(options.maxAlt)))
  }

  if (altitudeWhere.length) {
    clauses.push(Q.and(...altitudeWhere))
  }

  if (options.minUpdated) {
    clauses.push(
      Q.where('updatedAt', Q.gte(new Date(options.minUpdated).toISOString()))
    )
  }

  if (options.maxUpdated) {
    clauses.push(
      Q.where('updatedAt', Q.lte(new Date(options.maxUpdated).toISOString()))
    )
  }

  if (options.minCreated) {
    clauses.push(
      Q.where('createdAt', Q.gte(new Date(options.minCreated).toISOString()))
    )
  }

  if (options.maxCreated) {
    clauses.push(
      Q.where('createdAt', Q.lte(new Date(options.maxCreated).toISOString()))
    )
  }

  if (options.visitedYearly) {
    clauses.push(Q.where('visitedYearly', Q.eq(options.visitedYearly)))
  }

  const size = options.size || 10

  clauses.push(Q.skip(options.page * size))
  clauses.push(Q.take(size))

  const nests = database.collections.get<NestModel>('nests')
  const query = nests.query(...clauses).fetch()
  const totalQuery = nests.query().fetchCount()

  const [data, total] = await Promise.all([query, totalQuery])

  return {
    data,
    page: options.page,
    size,
    total,
    next: data.length > size ? 'true' : null,
    previous: options.page > 0 ? 'true' : null,
    timestamp: Date.now(),
    totalPages: Math.ceil(total / size),
  }
}

function detail(id: string) {
  return database.collections.get<NestModel>('nests').find(id)
}

async function create(nest: NestCreateDto) {
  const newNest = await database.write(async () => {
    const newNest = await database.get<NestModel>('nests').create(n => {
      n.nestCode = nest.nestCode!
      n.latitude = nest.latitude!
      n.longitude = nest.longitude!
      n.size = nest.size!
      n.species = nest.species!
      n.isFavorite = true
      n.floorname = nest.floorname!
      n.municipality = nest.municipality!
      n.canton = nest.canton!
      n.visitedYearly = nest.visitedYearly!
      n.place = nest.place!
    })

    return newNest
  })
  return newNest
}

function detail$(id: string) {
  return database.collections.get<NestModel>('nests').findAndObserve(id)
}

export const nestLocalService = {
  list,
  detail,
  create,
  detail$,
}
