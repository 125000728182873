import {t} from '@lingui/macro'
import {format} from 'date-fns'
import {Calendar as CalendarIcon} from 'lucide-react'
import {ChangeEventHandler, useState} from 'react'

import {Button} from '@/components/ui/button'
import {Calendar} from '@/components/ui/calendar'
import {FormControl} from '@/components/ui/form.tsx'
import {Input} from '@/components/ui/input'
import {Popover, PopoverContent, PopoverTrigger} from '@/components/ui/popover'
import {cn} from '@/lib/utils'

export function DatePicker({...field}) {
  const dateFormatString = t`dd.MM.yy HH:mm`
  const initialDate = new Date(Date.now())
  const [selected, setSelected] = useState<Date>(initialDate)
  const [timeValue, setTimeValue] = useState<Date>(initialDate)

  const handleTimeChange: ChangeEventHandler<HTMLInputElement> = e => {
    const [hours, minutes] = e.target.value.split(':').map(Number)

    const updatedDate = new Date(selected || initialDate)
    updatedDate.setHours(hours, minutes)

    setSelected(updatedDate)
    setTimeValue(updatedDate)
    field.onChange(updatedDate)
  }

  const handleDaySelect = (date: Date | undefined) => {
    if (!date) {
      setSelected(initialDate)
      setTimeValue(initialDate)
      field.onChange(initialDate)
      return
    }

    const updatedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeValue.getHours(),
      timeValue.getMinutes()
    )

    setSelected(updatedDate)
    setTimeValue(updatedDate)
    field.onChange(updatedDate)
  }
  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant={'outline'}
            className={cn(
              'w-full text-left gap-2',
              !selected && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="h-4 w-4" />
            {selected ? (
              format(selected, dateFormatString)
            ) : (
              <span>
                {format(
                  new Date(Date.now().toLocaleString()),
                  dateFormatString
                )}
              </span>
            )}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className={'w-auto p-3'}>
        <Input
          type="time"
          placeholder={field.value}
          value={format(timeValue, t`HH:mm`)}
          onChange={handleTimeChange}
        />
        <Calendar
          className={'pt-3'}
          mode="single"
          selected={selected}
          onSelect={handleDaySelect}
          initialFocus
          required
        />
      </PopoverContent>
    </Popover>
  )
}
