import {Trans} from '@lingui/macro'
import {SyncStatus} from '@nozbe/watermelondb/Model'
import {} from '@radix-ui/react-tooltip'
import {CheckCircle2, CircleEllipsis} from 'lucide-react'
import {FC} from 'react'

import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip'

import {useNestSyncStatus} from './useNestSyncStatus'

type Props = {
  id: string
}

const getSyncIcon = (status: SyncStatus | undefined) => {
  switch (status) {
    case 'synced':
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <CheckCircle2
                data-testid="NestSyncStatus.synced"
                className="text-green-800"
              />
            </TooltipTrigger>
            <TooltipContent>
              <Trans>Synchronised</Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    case 'updated':
    case 'created':
    case 'deleted':
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <CircleEllipsis
                data-testid="NestSyncStatus.not-synced"
                className="text-blue-800"
              />
            </TooltipTrigger>
            <TooltipContent>
              <Trans>Not synchronised yet</Trans>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    default:
      return status
  }
}

export const NestSyncStatus: FC<Props> = ({id}) => {
  const status = useNestSyncStatus(id)

  const icon = getSyncIcon(status)

  return <div style={{viewTransitionName: `nestSyncStatus-${id}`}}>{icon}</div>
}
