import {msg} from '@lingui/macro'
import {z} from 'zod'

export const ObservationWeatherDto = z.enum([
  'NONE',
  'CLEAR',
  'PARTIALLY_CLOUDY',
  'CLOUDY',
  'RAINY',
])
export type ObservationWeatherDto = z.infer<typeof ObservationWeatherDto>

export const OBSERVATION_WEATHER_MESSAGES = {
  [ObservationWeatherDto.enum.NONE]: msg`None`,
  [ObservationWeatherDto.enum.CLEAR]: msg`Clear`,
  [ObservationWeatherDto.enum.PARTIALLY_CLOUDY]: msg`Partially cloudy`,
  [ObservationWeatherDto.enum.CLOUDY]: msg`Cloudy`,
  [ObservationWeatherDto.enum.RAINY]: msg`Rainy`,
}
