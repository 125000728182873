import {queryOptions} from '@tanstack/react-query'

import {NestListOptionsDto} from './dtos/NestListOptionsDto'
import {nestLocalService} from './nestLocalService'

export const nestKeys = {
  all: () => ['local', 'nest'] as const,

  list: (options: NestListOptionsDto) =>
    [...nestKeys.all(), 'list', options] as const,

  details: () => [...nestKeys.all(), 'detail'] as const,
  detail: (id: string) => [...nestKeys.details(), id] as const,

  create: () => [...nestKeys.all(), 'create'] as const,
}

export const nestsQueryOptions = (options: NestListOptionsDto) =>
  queryOptions({
    queryKey: nestKeys.list(options),
    queryFn: () => nestLocalService.list(options),
  })

export const nestQueryOptions = (id: string) =>
  queryOptions({
    queryKey: nestKeys.detail(id),
    queryFn: () => nestLocalService.detail(id),
  })
