import {FC} from 'react'
import {Marker} from 'react-leaflet'

import {pinIcon} from '../icons/pin'

type Props = React.ComponentProps<typeof Marker>

export const BluePinMarker: FC<Props> = props => {
  return <Marker {...props} icon={pinIcon('#7000FF')} />
}
