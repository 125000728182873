import {t, Trans} from '@lingui/macro'
import {FC} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {z} from 'zod'

import {DatePicker} from '@/components/Observation/ObservationForm/DatePicker.tsx'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx'
import {Input} from '@/components/ui/input.tsx'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx'
import {Textarea} from '@/components/ui/textarea.tsx'
import {
  OBSERVATION_ACTIVITY_MESSAGES,
  ObservationActivityDto,
} from '@/lib/observation/dtos/ObservationActivityDto.ts'
import {ObservationDetailDto} from '@/lib/observation/dtos/ObservationDetailDto'
import {
  OBSERVATION_WEATHER_MESSAGES,
  ObservationWeatherDto,
} from '@/lib/observation/dtos/ObservationWeatherDto.ts'

export const ObservationDataForm = ObservationDetailDto.omit({
  id: true,
  author: true,
  location: true,
})
  .extend({
    nestId: z.string().uuid(),
  })
  .partial()
export type ObservationDataForm = z.infer<typeof ObservationDataForm>

type Props = {
  form: UseFormReturn<ObservationDataForm>
}

export const ObservationDataFormStep: FC<Props> = ({form}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className=" columns-2 py-3">
        <FormField
          control={form.control}
          name="temperature"
          render={({field}) => (
            <FormItem>
              <FormLabel>
                <Trans>Temperature</Trans>
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  placeholder={t`Enter temperature`}
                  onChange={e =>
                    field.onChange(e.target.value ? Number(e.target.value) : '')
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="observedAt"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t`Observed At`}</FormLabel>
              <DatePicker {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="activity"
          render={({field}) => (
            <FormItem>
              <FormLabel>
                <Trans>Activity</Trans>
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t`Select activity`} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.values(ObservationActivityDto.enum).map(activity => (
                    <SelectItem key={activity} value={activity}>
                      {t({
                        id: OBSERVATION_ACTIVITY_MESSAGES[activity].id,
                        message:
                          OBSERVATION_ACTIVITY_MESSAGES[activity].message,
                      })}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="weather"
          render={({field}) => (
            <FormItem>
              <FormLabel>
                <Trans>Weather</Trans>
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t`Select weather`} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.values(ObservationWeatherDto.enum).map(weather => (
                    <SelectItem key={weather} value={weather}>
                      {t({
                        id: OBSERVATION_WEATHER_MESSAGES[weather].id,
                        message: OBSERVATION_WEATHER_MESSAGES[weather].message,
                      })}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={form.control}
        name="comment"
        render={({field}) => (
          <FormItem>
            <FormLabel>
              <Trans>Observation</Trans>
            </FormLabel>
            <FormControl>
              <Textarea
                placeholder={t`Comment...`}
                className="resize-none h-48"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
