import {zodResolver} from '@hookform/resolvers/zod'
import {t} from '@lingui/macro'
import {useMutation} from '@tanstack/react-query'
import {createFileRoute, useNavigate, useParams} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'

import {ObservationForm} from '@/components/Observation/ObservationForm'
import {queryClient} from '@/lib/api/queryClient.ts'
import {nestService} from '@/lib/nest/nestService.ts'
import {nestKeys} from '@/lib/nest/query.ts'
import {ObservationCreateDto} from '@/lib/observation/dtos/ObservationCreateDto.ts'

export const Route = createFileRoute(
  '/nests/overview/all/$nestId/newObservation'
)({
  component: ObservationNewComponent,
})

function ObservationNewComponent() {
  const nestId = useParams({
    from: '/nests/overview/all/$nestId/newObservation',
    select: params => params.nestId,
  })
  const form = useForm<ObservationCreateDto>({
    resolver: zodResolver(ObservationCreateDto),
  })
  const navigation = useNavigate({from: '/nests/overview/all'})
  const {mutate} = useMutation({
    mutationFn: (newObservation: ObservationCreateDto) =>
      nestService.createObservation(newObservation),
    onSettled: () => queryClient.invalidateQueries({queryKey: nestKeys.all()}),
    onSuccess: data => {
      toast.success(t`New observation created`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
      const newId = data.data.id
      navigation({
        to: '..',
        from: '/nests/overview/all/$nestId',
        params: {nestId: newId},
        search: {page: 0},
      })
    },
  })
  const onSubmit = (observation: ObservationCreateDto) => {
    mutate({...observation, nestId})
  }
  return <ObservationForm form={form} onSubmit={onSubmit} />
}
