import {Trans} from '@lingui/macro'
import {Mail} from 'lucide-react'
import {FC} from 'react'

import {Card, CardContent} from '@/components/ui/card'

type VerifyComponentProps = {
  email: string
}

export const VerifyPage: FC<VerifyComponentProps> = ({email}) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card className="max-w-md w-full">
        <CardContent className="flex flex-col items-center text-center gap-4 p-6">
          <Mail className="w-10 h-10 text-blue-500" />
          <h1 className="text-2xl font-semibold">
            <Trans>Email Verification</Trans>
          </h1>
          <p className="text-lg">
            <Trans>
              We've sent a confirmation email to <strong>{email}</strong>.
            </Trans>
          </p>
          <p>
            <Trans>
              Please check your inbox and click the link to verify your email
              address.
            </Trans>
          </p>
        </CardContent>
      </Card>
    </div>
  )
}
