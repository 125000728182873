import {api} from '../api/api'
import {NestMarkerDto} from '../nest/dtos/NestMarkerDto'

function list() {
  return api.get('api/markers').json<NestMarkerDto[]>()
}

export const markerService = {
  list,
}
