import {createFileRoute} from '@tanstack/react-router'
import {z} from 'zod'

import {VerifyPage} from '@/components/Auth/RegistrationForm/verify.tsx'
import {MainLayout} from '@/components/Layout/MainLayout'

const verifyEmailSearchParamsSchema = z.object({
  email: z.string(),
})

export const Route = createFileRoute('/auth/register/verify')({
  validateSearch: verifyEmailSearchParamsSchema,
  component: VerifyComponent,
})

function VerifyComponent() {
  const search = Route.useSearch()
  const email = decodeURIComponent(search.email)
  return (
    <MainLayout>
      <div className="flex items-center justify-center flex-grow w-full h-full">
        <div className="w-full max-w-96">
          <VerifyPage email={email} />
        </div>
      </div>
    </MainLayout>
  )
}
