import {t, Trans} from '@lingui/macro'
import {LoaderCircle, LocateFixed} from 'lucide-react'
import {FC, useCallback, useEffect, useState} from 'react'
import {UseFormReturn, useWatch} from 'react-hook-form'
import {Popup} from 'react-leaflet'
import {toast} from 'sonner'
import {z} from 'zod'

import {CurrentLocationMarker} from '@/components/Map/CurrentLocationMarker'
import {BluePinMarker} from '@/components/Map/markers/BluePinMarker'
import {Button} from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {Input} from '@/components/ui/input'

import {SwissCoordinatesAlert} from './SwissCoordinatesAlert'
import {Map} from '../../../Map'
import {RecenterAutomatically} from '../../../Map/RecenterAutomatically'

export const NestLocationForm = z.object({
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
})
export type NestLocationForm = z.infer<typeof NestLocationForm>

type Props = {
  form: UseFormReturn<NestLocationForm>
}

export const NestLocationFormStep: FC<Props> = ({form}) => {
  const [isLocationLoading, setIsLocationLoading] = useState(false)

  const setCurrentLocation = useCallback(() => {
    if (!('geolocation' in navigator) || isLocationLoading) {
      return
    }

    setIsLocationLoading(true)
    navigator.geolocation.getCurrentPosition(
      position => {
        setIsLocationLoading(false)
        form.setValue('latitude', position.coords.latitude)
        form.setValue('longitude', position.coords.longitude)
      },
      error => {
        setIsLocationLoading(false)
        console.error(error)
        toast.error(t`Failed to get current location`)
      },
      {enableHighAccuracy: true, timeout: 60_000, maximumAge: 10_000}
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => setCurrentLocation(), [setCurrentLocation])

  const latitude = useWatch({control: form.control, name: 'latitude'})
  const longitude = useWatch({control: form.control, name: 'longitude'})

  return (
    <div className="flex flex-col gap-4">
      <Map
        latitude={latitude}
        longitude={longitude}
        onMove={([latitude, longitude]) => {
          form.setValue('latitude', latitude)
          form.setValue('longitude', longitude)
        }}
      >
        {latitude && longitude && (
          <>
            <BluePinMarker position={[latitude, longitude]}>
              <Popup>
                <Trans>New nest location</Trans>
              </Popup>
            </BluePinMarker>
            <RecenterAutomatically latitute={latitude} longitude={longitude} />
          </>
        )}
        <CurrentLocationMarker />
      </Map>
      <div className="flex items-end flex-grow gap-4">
        <FormField
          control={form.control}
          name="latitude"
          render={({field}) => (
            <FormItem className="flex-grow">
              <FormLabel>
                <Trans>Latitude</Trans>
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="number"
                  onChange={e => field.onChange(e.target.valueAsNumber)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="longitude"
          render={({field}) => (
            <FormItem className="flex-grow">
              <FormLabel>
                <Trans>Longitude</Trans>
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="number"
                  onChange={e => field.onChange(e.target.valueAsNumber)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="button"
          onClick={() => setCurrentLocation()}
          disabled={isLocationLoading}
          data-testid="NestForm.locate"
        >
          {isLocationLoading ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            <LocateFixed />
          )}
        </Button>
      </div>
      {latitude && longitude && (
        <>
          <SwissCoordinatesAlert latitude={latitude} longitude={longitude} />
        </>
      )}
    </div>
  )
}
