export const AVATAR_BACKGROUNDS = [
  '#4F6161',
  '#445555',
  '#3A4848',
  '#2F3E46',
  '#263A3B',
  '#1D3030',
  '#142626',
  '#0B1C1C',
  '#752E00',
  '#692700',
  '#5E2100',
  '#532200',
  '#482500',
  '#3D1F00',
  '#321800',
  '#271200',
  '#1C0B00',
  '#110500',
  '#060000',
  '#3B3F36',
  '#3E3D31',
  '#4A493D',
  '#484834',
  '#4E4C3A',
  '#59584D',
  '#5B5A50',
  '#6D6B5F',
  '#4C5C5C',
  '#3F4A4A',
  '#3D4D4D',
  '#323A3A',
  '#263D3D',
  '#214040',
  '#1A3333',
  '#142828',
  '#0F1C1C',
  '#0B1F1F',
  '#0C2222',
  '#0D2525',
  '#0E2828',
  '#0F2B2B',
  '#0C2E2E',
  '#0D3131',
  '#0E3434',
  '#0F3737',
  '#0A3A3A',
  '#0B3D3D',
]
