import {Trans} from '@lingui/macro'
import {Binoculars, DatabaseIcon, PinIcon} from 'lucide-react'
import {FC} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {
  ObservationDataForm,
  ObservationDataFormStep,
} from '@/components/Observation/ObservationForm/ObservationDataFormStep'
import {Button} from '@/components/ui/button'
import {Form} from '@/components/ui/form'
import {Stepper} from '@/components/ui/Stepper'
import {StepperItem} from '@/components/ui/Stepper/StepperItem'

import {NestDataForm, NestDataFormStep} from './NestDataFormStep'
import {NestLocationForm, NestLocationFormStep} from './NestLocationFormStep'

type Props = {
  step: number
  onNextStep: () => void
  onPreviousStep: () => void
  onSubmit: () => void
  nestLocationForm: UseFormReturn<NestLocationForm>
  nestDataForm: UseFormReturn<NestDataForm>
  observationDataForm: UseFormReturn<ObservationDataForm>
}

const MAX_STEPS = 3

export const NestForm: FC<Props> = ({
  step,
  onNextStep,
  onPreviousStep,
  onSubmit,
  nestLocationForm,
  nestDataForm,
  observationDataForm,
}) => {
  return (
    <>
      <Stepper activeStep={step} className="py-8">
        <StepperItem step={0} icon={<PinIcon />}>
          <Trans>1. Nestposition</Trans>
        </StepperItem>
        <StepperItem step={1} icon={<DatabaseIcon />}>
          <Trans>2. Nestdaten</Trans>
        </StepperItem>
        <StepperItem step={2} icon={<Binoculars />}>
          <Trans>3. Beobachtung</Trans>
        </StepperItem>
      </Stepper>

      {step === 0 && (
        <Form {...nestLocationForm}>
          <form onSubmit={nestLocationForm.handleSubmit(onNextStep)}>
            <NestLocationFormStep form={nestLocationForm} />
            <ButtonGroup step={step} onPreviousStep={onPreviousStep} />
          </form>
        </Form>
      )}
      {step === 1 && (
        <Form {...nestDataForm}>
          <form onSubmit={nestDataForm.handleSubmit(onNextStep)}>
            <NestDataFormStep form={nestDataForm} />
            <ButtonGroup step={step} onPreviousStep={onPreviousStep} />
          </form>
        </Form>
      )}
      {step === 2 && (
        <Form {...observationDataForm}>
          <form onSubmit={observationDataForm.handleSubmit(onSubmit)}>
            <ObservationDataFormStep form={observationDataForm} />
            <ButtonGroup step={step} onPreviousStep={onPreviousStep} />
          </form>
        </Form>
      )}
    </>
  )
}

type ButtonGroupProps = {
  step: number
  onPreviousStep: () => void
}

const ButtonGroup: FC<ButtonGroupProps> = ({step, onPreviousStep}) => {
  return (
    <div className="flex justify-between py-4">
      {step > 0 ? (
        <Button
          variant="secondary"
          onClick={() => onPreviousStep()}
          disabled={step === 0}
          data-testid="NestForm.previous"
          type="button"
        >
          <Trans>Previous</Trans>
        </Button>
      ) : (
        <div></div>
      )}

      <div className="flex gap-4">
        {step === MAX_STEPS - 1 ? (
          <Button type="submit" data-testid="NestForm.submit">
            <Trans>Submit</Trans>
          </Button>
        ) : (
          <Button type="submit" data-testid="NestForm.next">
            <Trans>Next</Trans>
          </Button>
        )}
      </div>
    </div>
  )
}
