import {queryOptions} from '@tanstack/react-query'

import {userService} from '@/lib/user/userService'

export const userKeys = {
  all: () => ['user'] as const,

  list: () => [...userKeys.all(), 'list'] as const,

  details: () => [...userKeys.all(), 'detail'] as const,
  detail: (id: string) => [...userKeys.details(), id] as const,

  create: () => [...userKeys.all(), 'create'] as const,
  updates: () => [...userKeys.all(), 'update'] as const,
  update: (id: string) => [...userKeys.updates(), id] as const,
}

export const usersQueryOptions = () =>
  queryOptions({
    queryKey: userKeys.list(),
    queryFn: userService.all,
  })
