import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useEffect, useState} from 'react'

import {ApiPagination} from '@/components/ApiPagination'
import {Map} from '@/components/Map'
import {CurrentLocationMarker} from '@/components/Map/CurrentLocationMarker'
import {NestFilter} from '@/components/Nest/NestFilter'
import {NestList} from '@/components/Nest/NestList'
import {NestListError} from '@/components/Nest/NestList/error'
import {NestListLoading} from '@/components/Nest/NestList/loading'
import {AllNestMarkers} from '@/components/Nest/NestMarkers/all'
import useDebounce from '@/lib/hooks/useDebounce'
import {NestListOptionsDto} from '@/lib/nest/dtos/NestListOptionsDto'
import {nestsQueryOptions} from '@/lib/nest/query'

export const Route = createFileRoute('/nests/overview/all/')({
  loaderDeps: ({search}) => search,
  loader: ({context: {queryClient}, deps: search}) =>
    queryClient.ensureQueryData(nestsQueryOptions(search)),
  pendingComponent: NestListLoading,
  component: NestComponent,
  errorComponent: NestListError,
  validateSearch: NestListOptionsDto,
})

function NestComponent() {
  const search = Route.useSearch()
  const navigate = useNavigate({
    from: Route.fullPath,
  })
  const {data: nests, page, next, previous, totalPages} = Route.useLoaderData()

  const [filters, setFilters] = useState(search)
  const debouncedFilters = useDebounce(filters, 200)

  useEffect(() => {
    const scrollPosition = window.scrollY

    // Sync filters with URL
    navigate({
      search: (prev: any) => ({...prev, ...debouncedFilters}),
      viewTransition: false,
      replace: true,
      params: true,
    })

    window.scrollTo(0, scrollPosition)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters])

  const handleResetFilters = () => {
    const initialFilters: NestListOptionsDto = {
      page: 0,
    }
    setFilters(initialFilters)
    navigate({
      search: initialFilters,
      viewTransition: false,
      replace: true,
      params: true,
    })
  }

  return (
    <>
      <Map>
        <AllNestMarkers />
        <CurrentLocationMarker />
      </Map>
      <NestFilter
        filters={filters}
        onFilterChange={setFilters}
        onResetFilters={handleResetFilters}
      />
      <NestList from="/nests/overview/all" nests={nests} />
      <ApiPagination
        next={next}
        page={page}
        previous={previous}
        totalPages={totalPages}
      />
    </>
  )
}
