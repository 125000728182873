import {Trans} from '@lingui/macro'
import {createFileRoute, Link} from '@tanstack/react-router'
import {Check} from 'lucide-react'

import {MainLayout} from '@/components/Layout/MainLayout'
import {Button} from '@/components/ui/button.tsx'
import {Card, CardContent} from '@/components/ui/card.tsx'

export const Route = createFileRoute('/auth/register/verified')({
  component: VerifiedComponent,
})

function VerifiedComponent() {
  return (
    <MainLayout>
      <div className="flex items-center justify-center flex-grow w-full h-full">
        <div className="w-full max-w-96">
          <div className="flex items-center justify-center w-full h-full">
            <Card className="max-w-md w-full">
              <CardContent className="flex flex-col items-center text-center gap-4 p-6">
                <Check className="w-10 h-10 text-green-500" />{' '}
                <h1 className="text-2xl font-semibold">
                  <Trans>Verification Successful</Trans>
                </h1>
                <p className="text-lg">
                  <Trans>Your account has been successfully verified.</Trans>
                </p>
                <Button asChild variant="default">
                  <Link to="/auth" search={''}>
                    <Trans>Go to Login</Trans>
                  </Link>
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
