/* eslint-disable lingui/no-unlocalized-strings */

import {Icon} from 'leaflet'

export const pinIcon = (fill: string) => {
  const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="36" viewBox="0 0 30 36" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M25.6066 25.4559C31.4645 19.6325 31.4645 10.1909 25.6066 4.36753C19.7487 -1.45584 10.2513 -1.45584 4.3934 4.36754C-1.46446 10.1909 -1.46447 19.6325 4.39338 25.4558H4.39334L4.3934 25.4559L14.9999 36L25.5782 25.4841C25.5876 25.4747 25.5971 25.4653 25.6066 25.4559Z"
    fill="${fill}" />
  <ellipse cx="15" cy="14.9117" rx="7.45585" ry="7.5" transform="rotate(90 15 14.9117)" fill="white" />
</svg>
`

  const iconUrl = 'data:image/svg+xml,' + encodeURIComponent(svg)

  return new Icon({
    iconUrl,
    iconSize: [30, 36],
    iconAnchor: [15, 36],
  })
}
