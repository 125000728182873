import {t} from '@lingui/macro'
import {useMutation} from '@tanstack/react-query'
import {FC} from 'react'
import {toast} from 'sonner'

import {Favorite} from '@/components/ui/Favorite'
import {queryClient} from '@/lib/api/queryClient'
import {sync} from '@/lib/db/sync'
import {NestDetailDto} from '@/lib/nest/dtos/NestDetailDto'
import {NestItemDto} from '@/lib/nest/dtos/NestItemDto'
import {nestService} from '@/lib/nest/nestService.ts'
import {nestKeys} from '@/lib/nest/query.ts'

type Props = {
  nest: NestItemDto | NestDetailDto
}

export const NestFavorite: FC<Props> = ({nest}) => {
  const onSuccess = () => {
    const message = nest.isFavorite ? t`Nest unfavorited` : t`Nest favorited`
    toast.success(message, {
      cancel: {
        label: t`Dismiss`,
        onClick: () => toast.dismiss(),
      },
    })
    sync()
  }

  const handleFavorite = () => {
    mutate(nest.id)
  }

  const {mutate} = useMutation({
    mutationFn: (nestId: string) => nestService.favorite(nestId),
    onSettled: () => queryClient.invalidateQueries({queryKey: nestKeys.all()}),
    mutationKey: nestKeys.favorite(nest.id),
    onSuccess,
  })

  return (
    <Favorite
      style={{viewTransitionName: `nestFavorite-${nest.id}`}}
      onClick={() => handleFavorite()}
      selected={nest.isFavorite}
    />
  )
}
