import {Model} from '@nozbe/watermelondb'
import {field, date} from '@nozbe/watermelondb/decorators'

import {tableSchemaTypesecure} from '@/lib/db/modelSchema'
import {NestPlaceDto} from '@/lib/nest/dtos/NestPlaceDto.ts'

import {NestDetailDto} from '../dtos/NestDetailDto'
import {NestSizeDto} from '../dtos/NestSizeDto'

export class NestModel extends Model implements NestDetailDto {
  static readonly table = 'nests'

  @field('nestCode') nestCode!: string
  @field('latitude') latitude!: number
  @field('longitude') longitude!: number
  @field('size') size!: NestSizeDto
  @field('place') place!: NestPlaceDto
  @field('isFavorite') isFavorite!: boolean
  @field('species') species!: string
  @field('floorname') floorname!: string
  @field('user') user!: string
  @field('municipality') municipality!: string
  @field('canton') canton!: string
  @field('height') height!: number
  @field('visitedYearly') visitedYearly!: boolean
  // TODO: Handle dates
  @date('createdAt') createdAt!: string
  @date('lastVisited') lastVisited!: string
  // @date('updatedAt') updatedAt!: string
}

export const nestSchema = tableSchemaTypesecure<typeof NestModel>({
  name: 'nests',
  columns: {
    nestCode: {
      type: 'string',
    },
    latitude: {
      type: 'number',
    },
    longitude: {
      type: 'number',
    },
    size: {
      type: 'string',
    },
    isFavorite: {
      type: 'boolean',
    },
    species: {
      type: 'string',
    },
    place: {
      type: 'string',
    },
    floorname: {
      type: 'string',
    },
    municipality: {
      type: 'string',
    },
    canton: {
      type: 'string',
    },
    height: {
      type: 'number',
    },
    user: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
    visitedYearly: {
      type: 'boolean',
    },
    lastVisited: {
      type: 'string',
    },
    // updatedAt: {
    //   type: 'string',
    // },
  },
})
