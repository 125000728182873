import {zodResolver} from '@hookform/resolvers/zod'
import {t, Trans} from '@lingui/macro'
import {useSuspenseQuery, useMutation} from '@tanstack/react-query'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'

import {NestDetailError} from '@/components/Nest/NestDetail/error'
import {NestDetailLoading} from '@/components/Nest/NestDetail/loading'
import {
  NestDataForm,
  NestDataFormStep,
} from '@/components/Nest/NestForm/NestDataFormStep'
import {Button} from '@/components/ui/button'
import {Form} from '@/components/ui/form'
import {queryClient} from '@/lib/api/queryClient'
import {nestService} from '@/lib/nest/nestService'
import {nestQueryOptions, nestKeys} from '@/lib/nest/query'

export const Route = createFileRoute('/nests/overview/all/$nestId/edit/data')({
  component: NestDataEditComponent,
  loader: ({context: {queryClient}, params: {nestId}}) =>
    queryClient.ensureQueryData(nestQueryOptions(nestId)),
  pendingComponent: NestDetailLoading,
  errorComponent: NestDetailError,
})

function NestDataEditComponent() {
  const {nestId} = Route.useParams()
  const nestQuery = useSuspenseQuery(nestQueryOptions(nestId))

  const form = useForm<NestDataForm>({
    resolver: zodResolver(NestDataForm),
    defaultValues: nestQuery.data.data,
  })

  const navigation = useNavigate({from: Route.fullPath})

  const {mutate} = useMutation({
    mutationFn: (newNest: NestDataForm) =>
      nestService.update(nestId, {...nestQuery.data.data, ...newNest}),
    onSettled: () => queryClient.invalidateQueries({queryKey: nestKeys.all()}),
    mutationKey: nestKeys.update(nestId),
    onSuccess: () => {
      toast.success(t`Nest updated`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
      navigation({
        to: '/nests/overview/all/$nestId',
        params: {nestId},
        from: Route.fullPath,
      })
    },
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(data => mutate(data))}
        className="flex flex-col gap-4"
      >
        <NestDataFormStep form={form} />
        <Button type="submit" data-testid="NestDataEdit.submit">
          <Trans>Update</Trans>
        </Button>
      </form>
    </Form>
  )
}
