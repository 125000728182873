import {api} from '@/lib/api/api.ts'
import {RegistrationDto} from '@/lib/auth/dtos/RegistrationDto.ts'

import {LoginDto} from './dtos/LoginDto'
import {ApiResponseDto} from '../api/dtos/ApiResponseDto'
import {UserDto} from '../user/dtos/UserDto.ts'

export function login(json: LoginDto) {
  return api.post('api/user/login', {json}).json<ApiResponseDto<UserDto>>()
}

export function register(json: RegistrationDto) {
  return api.post('api/user/register', {json}).json<ApiResponseDto<UserDto>>()
}

export const authService = {
  login,
  register,
}
