import {keepPreviousData, queryOptions} from '@tanstack/react-query'

import {ApiPaginationOptionsDto} from '@/lib/api/dtos/ApiPaginationOptionsDto.ts'
import {nestService} from '@/lib/nest/nestService.ts'

import {NestListOptionsDto} from './dtos/NestListOptionsDto'

export const nestKeys = {
  all: () => ['nest'] as const,
  observation: (id: string, page: number) =>
    [...nestKeys.observations(id, page), 'observation', id] as const,

  observations: (id: string, page: number) =>
    [...nestKeys.all(), 'observations', page, id] as const,

  list: (options: NestListOptionsDto) =>
    [...nestKeys.all(), 'list', options] as const,

  details: () => [...nestKeys.all(), 'detail'] as const,
  detail: (id: string) => [...nestKeys.details(), id] as const,
  create: () => [...nestKeys.all(), 'create'] as const,
  updates: () => [...nestKeys.all(), 'update'] as const,
  update: (id: string) => [...nestKeys.updates(), id] as const,

  favorites: () => [...nestKeys.all(), 'favorite'] as const,
  favorite: (id: string) => [...nestKeys.favorites(), id] as const,
}

export const nestsQueryOptions = (options: NestListOptionsDto) =>
  queryOptions({
    queryKey: nestKeys.list(options),
    queryFn: () => nestService.all(options),
    placeholderData: keepPreviousData,
  })

export const nestQueryOptions = (id: string) =>
  queryOptions({
    queryKey: nestKeys.detail(id),
    queryFn: () => nestService.detail(id),
  })

export const observationsQueryOptions = (
  id: string,
  {page}: ApiPaginationOptionsDto = {page: 0}
) =>
  queryOptions({
    queryKey: nestKeys.observations(id, page),
    queryFn: () => nestService.observations(id, {page: 0}),
  })

export const observationQueryOptions = (
  id: string,
  {page}: ApiPaginationOptionsDto = {page: 0}
) =>
  queryOptions({
    queryKey: nestKeys.observation(id, page),
    queryFn: () => nestService.observation(id),
  })
