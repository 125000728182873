import {t, Trans} from '@lingui/macro'
import {FC} from 'react'

import {ObservationTableRow} from '@/components/Observation/ObservationTable/ObservationTableRow.tsx'
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx'
import {ObservationItemDto} from '@/lib/observation/dtos/ObservationItemDto.ts'

type Props = {
  observations: ObservationItemDto[] | undefined
}

export const ObservationTable: FC<Props> = ({observations}) => {
  return (
    <Table className="table-fixed">
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/6">
            <Trans>Date</Trans>
          </TableHead>
          <TableHead className="w-7/12">
            <Trans>Comment</Trans>
          </TableHead>
          <TableHead className="w-1/6 ">
            <Trans>User</Trans>
          </TableHead>
          <TableHead className={'text-right w-1/12'}></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {observations && observations.length > 0
          ? observations.map(observation => (
              <ObservationTableRow
                key={observation.id}
                observation={observation}
              />
            ))
          : t`no Observations`}
        {}
      </TableBody>
    </Table>
  )
}
