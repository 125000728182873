import {Map as LeafletMap} from 'leaflet'
import {FC, PropsWithChildren, useEffect, useState} from 'react'
import {MapContainer, TileLayer} from 'react-leaflet'

import 'leaflet/dist/leaflet.css'
import {OfflineMap} from './OfflineMap'

type Props = {
  latitude?: number
  longitude?: number
  onMove?: (center: [number, number]) => void
}

const TEMPLATE_URL =
  'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg'

export const Map: FC<PropsWithChildren<Props>> = ({
  latitude,
  longitude,
  children,
  onMove,
}) => {
  const [map, setMap] = useState<LeafletMap | null>(null)

  useEffect(() => {
    if (!map) {
      return
    }
    map.on('drag', () => {
      const center = map.getCenter()
      onMove?.([center.lat, center.lng])
    })

    return () => {
      map.off('move')
    }
  }, [map, onMove])

  return (
    <>
      <div className="relative w-full overflow-hidden rounded-lg h-96">
        <MapContainer
          className="h-full"
          center={[latitude || 47.479243, longitude || 8.213256]}
          zoom={8}
          scrollWheelZoom={false}
          ref={setMap}
        >
          <TileLayer
            url={TEMPLATE_URL}
            bounds={[
              [45.398181, 5.140242],
              [48.230651, 11.47757],
            ]}
          />
          {children}
        </MapContainer>
      </div>
      <OfflineMap map={map} url={TEMPLATE_URL} />
    </>
  )
}
