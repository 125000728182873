import {z} from 'zod'

import {ObservationActivityDto} from '@/lib/observation/dtos/ObservationActivityDto.ts'

import {ObservationWeatherDto} from './ObservationWeatherDto.ts'

export const ObservationDetailDto = z.object({
  id: z.string(),
  comment: z.string(),
  weather: ObservationWeatherDto,
  activity: ObservationActivityDto,
  temperature: z.number(),
  observedAt: z.date(),
  location: z.string(),
  author: z.string(),
})
export type ObservationDetailDto = z.infer<typeof ObservationDetailDto>
