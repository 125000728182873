import {z} from 'zod'

import {ObservationDetailDto} from './ObservationDetailDto.ts'

export const ObservationCreateDto = ObservationDetailDto.omit({
  id: true,
  author: true,
  location: true,
})
  .extend({
    nestId: z.string().uuid(),
  })
  .partial()
export type ObservationCreateDto = z.infer<typeof ObservationCreateDto>
