import {zodResolver} from '@hookform/resolvers/zod'
import {t, Trans} from '@lingui/macro'
import {useMutation} from '@tanstack/react-query'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'
import {z} from 'zod'

import {NestForm} from '@/components/Nest/NestForm'
import {NestDataForm} from '@/components/Nest/NestForm/NestDataFormStep'
import {NestLocationForm} from '@/components/Nest/NestForm/NestLocationFormStep'
import {ObservationDataForm} from '@/components/Observation/ObservationForm/ObservationDataFormStep'
import {Back} from '@/components/ui/back'
import {Card, CardContent} from '@/components/ui/card'
import {queryClient} from '@/lib/api/queryClient'
import {NestCreateDto} from '@/lib/nest/dtos/NestCreateDto'
import {nestLocalService} from '@/lib/nest/nestLocalService'
import {nestKeys} from '@/lib/nest/query'

const nestNewQueryParams = z.object({
  step: z.number().int().positive().catch(0).default(0),
})

export const Route = createFileRoute('/nests/overview/favorites/new')({
  component: NestNewComponent,
  validateSearch: nestNewQueryParams,
})

function NestNewComponent() {
  const {step} = Route.useSearch()
  const navigation = useNavigate({from: Route.fullPath})

  const nextStep = () => navigation({search: {step: step + 1}})
  const previousStep = () => navigation({search: {step: step - 1}})

  const nestLocationForm = useForm<NestLocationForm>({
    resolver: zodResolver(NestLocationForm),
  })

  const nestDataForm = useForm<NestDataForm>({
    resolver: zodResolver(NestDataForm),
  })

  const observationForm = useForm<ObservationDataForm>({
    resolver: zodResolver(ObservationDataForm),
  })

  const {mutate} = useMutation({
    mutationFn: (newNest: NestCreateDto) => nestLocalService.create(newNest),
    onSettled: () => queryClient.invalidateQueries({queryKey: ['local']}),
    mutationKey: nestKeys.create(),
    onSuccess: data => {
      toast.success(t`New nest created`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })

      const newId = data.id
      navigation({
        to: '../$nestId',
        params: {nestId: newId},
      })
    },
  })

  const onSubmit = () => {
    mutate({
      ...nestLocationForm.getValues(),
      ...nestDataForm.getValues(),
    })
  }

  return (
    <div className="flex flex-col gap-4" data-testid="NestForm">
      <div className="flex items-center gap-4 vt-name-[nestFormBack]">
        <Back to="../" params={{}} from={Route.fullPath} />

        <div className="text-xl font-semibold tracking-tight shrink-0 whitespace-nowrap sm:grow-0">
          <Trans>Create new Nest</Trans>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <Card className="vt-name-[nestForm]">
          <CardContent>
            <NestForm
              step={step}
              onNextStep={nextStep}
              onPreviousStep={previousStep}
              onSubmit={onSubmit}
              nestLocationForm={nestLocationForm}
              nestDataForm={nestDataForm}
              observationDataForm={observationForm}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
