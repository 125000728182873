import {msg} from '@lingui/macro'
import {z} from 'zod'

export const NestPlaceDto = z.enum([
  'NONE',
  'OPEN_COUNTRY',
  'FOREST_EDGE',
  'FOREST',
  'MIXED',
])
export type NestPlaceDto = z.infer<typeof NestPlaceDto>

export const NEST_PLACE_MESSAGES = {
  [NestPlaceDto.enum.NONE]: msg`None`,
  [NestPlaceDto.enum.OPEN_COUNTRY]: msg`In the open country`,
  [NestPlaceDto.enum.FOREST_EDGE]: msg`At the edge of a forest`,
  [NestPlaceDto.enum.FOREST]: msg`In the forest`,
  [NestPlaceDto.enum.MIXED]: msg`In mixed habitat`,
}
