import {keepPreviousData, queryOptions} from '@tanstack/react-query'

import {markerService} from './markerService'

export const markerKeys = {
  list: () => ['marker'] as const,
}

export const markersQueryOptions = () =>
  queryOptions({
    queryKey: markerKeys.list(),
    queryFn: () => markerService.list(),
    placeholderData: keepPreviousData,
  })
