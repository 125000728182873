import {createContext, FC, ReactElement} from 'react'

import {cn} from '@/lib/utils'

import {StepperItem} from './StepperItem'

type Props = {
  activeStep: number
  children: ReactElement<typeof StepperItem>[]
  className?: string
}

type Context = {
  activeStep: number
  totalSteps: number
}

export const StepperContext = createContext<Context | undefined>(undefined)

export const Stepper: FC<Props> = ({children, activeStep, className}) => {
  return (
    <StepperContext.Provider value={{activeStep, totalSteps: children.length}}>
      <div className={cn('flex gap-4', className)}>{children}</div>
    </StepperContext.Provider>
  )
}
