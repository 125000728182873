import {t} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {format} from 'date-fns'
import {EllipsisIcon} from 'lucide-react'
import {FC} from 'react'

import {TableCell, TableRow} from '@/components/ui/table.tsx'
import {ObservationDetailDto} from '@/lib/observation/dtos/ObservationDetailDto.ts'
type Props = {
  observation: ObservationDetailDto
}
export const ObservationTableRow: FC<Props> = ({observation}) => {
  return (
    <TableRow key={observation.id}>
      <TableCell className={'font-medium p-0'}>
        {format(observation.observedAt.toLocaleString(), t`dd.MM.yy HH:mm`)}
      </TableCell>
      <TableCell className={' truncate'}>{observation.comment}</TableCell>
      <TableCell className="font-medium">{observation.author}</TableCell>
      <TableCell className={'text-right'}>
        <Link
          search={observation.id}
          to={'./observation/$observationId'}
          from={'/nests/overview/all/$nestId'}
          params={{observationId: observation.id}}
          className={'flex justify-end'}
        >
          <EllipsisIcon />
        </Link>
      </TableCell>
    </TableRow>
  )
}
