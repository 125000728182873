import {Trans} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {CalendarIcon} from 'lucide-react'
import {FC} from 'react'

import {Badge} from '@/components/ui/badge.tsx'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {NestItemDto} from '@/lib/nest/dtos/NestItemDto'

import nestImage1 from '../../../assets/nest1.jpg'
import nestImage2 from '../../../assets/nest2.jpg'
import nestImage3 from '../../../assets/nest3.jpg'
import nestImage4 from '../../../assets/nest4.jpg'
import nestImage5 from '../../../assets/nest5.jpg'
import nestImage6 from '../../../assets/nest6.jpg'
import nestImage7 from '../../../assets/nest7.jpg'
import nestImage8 from '../../../assets/nest8.jpg'
import {NestFavorite} from '../NestFavorite'
import {NestSyncStatus} from '../NestSyncStatus'

const images = [
  nestImage1,
  nestImage2,
  nestImage3,
  nestImage4,
  nestImage5,
  nestImage6,
  nestImage7,
  nestImage8,
]

type FromPaths = '/nests/overview/all' | '/nests/overview/favorites'
type Props = {
  nest: NestItemDto
  from: FromPaths
}

function stringToHash(str: string) {
  let hash = 0

  if (str.length == 0) return hash

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }

  return Math.abs(hash)
}

export const NestItem: FC<Props> = ({nest, from}) => {
  // Temporary solution to get a random image for each nest
  const imageId = stringToHash(nest.id) % images.length

  return (
    <Link
      from={from}
      to="./$nestId"
      className="flex flex-grow"
      params={{nestId: nest.id}}
      data-testid={`NestItem.${nest.id}`}
    >
      <Card
        style={{viewTransitionName: `nestCard-${nest.id}`}}
        className="w-full p-3 md:p-4 hover:bg-accent"
      >
        <CardContent className="flex gap-2 p-0 m-0">
          <div className="w-1/3 aspect-square">
            {/*TODO: SetImage*/}
            <img
              src={images[imageId]}
              alt={nestImage1.toString()}
              className="object-cover w-full h-full rounded-md"
            />
          </div>
          <div className="flex flex-col justify-between w-2/3 p-4 md:p-6">
            <CardHeader className="flex flex-row justify-between p-0 space-y-0">
              <div className="space-y-1.5">
                <CardDescription data-testid={`NestItem.${nest.id}.nestCode`}>
                  {nest.nestCode}
                </CardDescription>
                <CardTitle
                  className="md:text-2xl"
                  data-testid={`NestItem.${nest.id}.species`}
                >
                  {nest.species}
                </CardTitle>
              </div>
              <div className="flex flex-col gap-4">
                <NestFavorite nest={nest} />
                <NestSyncStatus id={nest.id} />
              </div>
            </CardHeader>
            <div>
              {nest.visitedYearly && (
                <Badge
                  variant="outline"
                  data-testid={`NestItem.${nest.id}.visitedYearly`}
                >
                  <Trans>is visited yearly</Trans>
                </Badge>
              )}
            </div>
            <CardFooter className="flex items-center gap-2 p-0 text-sm text-muted-foreground">
              <CalendarIcon className="w-4 h-4" />
              <Trans>Last visited</Trans>:
              {/* TODO(REAL_DATE): Replace this with real data */}
              <span>{new Date().toLocaleDateString('de-DE')}</span>
            </CardFooter>
          </div>
        </CardContent>
      </Card>
    </Link>
  )
}
