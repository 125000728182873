import {msg} from '@lingui/macro'
import {z} from 'zod'

export const ObservationActivityDto = z.enum([
  'NONE',
  'ZERO',
  'LESS10',
  'LESS100',
  'LESS1000',
  'MORE1000',
])
export type ObservationActivityDto = z.infer<typeof ObservationActivityDto>

export const OBSERVATION_ACTIVITY_MESSAGES = {
  [ObservationActivityDto.enum.NONE]: msg`None`,
  [ObservationActivityDto.enum.ZERO]: msg`No Activity`,
  [ObservationActivityDto.enum.LESS10]: msg`Less than 10`,
  [ObservationActivityDto.enum.LESS100]: msg`Less than 100`,
  [ObservationActivityDto.enum.LESS1000]: msg`Less than 1000`,
  [ObservationActivityDto.enum.MORE1000]: msg`More than 1000`,
}
