import {Trans} from '@lingui/macro'
import {
  Link,
  Outlet,
  createFileRoute,
  useRouterState,
  redirect,
} from '@tanstack/react-router'

import {MainLayout} from '@/components/Layout/MainLayout'
import {NestListTabs} from '@/components/Nest/NestList/NestListTabs'
import {NEST_LIST_TAB} from '@/components/Nest/NestList/NestListTabs/types'
import {Button} from '@/components/ui/button'
import {useAuthStore} from '@/lib/auth/authStore.ts'

export const Route = createFileRoute('/nests/overview')({
  component: NestOverviewLayout,
  beforeLoad: () => {
    const isAuthenticated: boolean = useAuthStore.getState().isAuthenticated()
    if (!isAuthenticated) {
      throw redirect({
        to: '/auth',
      })
    }
  },
})

function NestOverviewLayout() {
  const router = useRouterState()
  const [role, email] = useAuthStore(state => [state.role, state.email])

  const currentTab = router.location.pathname.includes('favorites')
    ? NEST_LIST_TAB.FAVORITES
    : NEST_LIST_TAB.ALL

  return (
    <MainLayout>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <h1 className="flex-1 text-xl font-semibold tracking-tight shrink-0 whitespace-nowrap sm:grow-0 vt-name-[nestsTitle]">
            <Trans>Nests</Trans>
          </h1>
          <p>
            <Trans>You: </Trans> {email} {role}
          </p>
          <div className="vt-name-[nestNewButton] flex gap-2">
            <Link
              to="./new"
              from="/nests/overview/favorites"
              search={{step: 0}}
            >
              <Button variant="outline">
                <Trans>New Nest</Trans>
              </Button>
            </Link>
          </div>
        </div>
        <NestListTabs value={currentTab} />
        <Outlet />
      </div>
    </MainLayout>
  )
}
