import {i18n} from '@lingui/core'
import {Trans} from '@lingui/macro'
import {ChevronDown} from 'lucide-react'
import {FC, useState} from 'react'

import {Badge} from '@/components/ui/badge.tsx'
import {Button} from '@/components/ui/button.tsx'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import {Input} from '@/components/ui/input.tsx'
import UserAvatar from '@/components/Users/UserAvatar'
import {useAuthStore} from '@/lib/auth/authStore.ts'
import {UserOverviewDto} from '@/lib/user/dtos/UserOverviewDto.ts'
import {USER_ROLE_MESSAGES, UserRoleDto} from '@/lib/user/dtos/UserRoleDto.ts'

type UserRoleKey = keyof typeof USER_ROLE_MESSAGES

type Props = {
  users: UserOverviewDto[] | []
  onChange: (user: UserOverviewDto) => void // Callback to handle role changes
}

export const UserTable: FC<Props> = ({users, onChange}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRole, setSelectedRole] = useState<string | null>(null)

  const handleRoleChange = (userId: string, newRole: string) => {
    const updatedUser = users.find(user => user.id === userId)
    if (updatedUser) {
      const updatedUserWithNewRole = {...updatedUser, role: newRole}
      onChange(updatedUserWithNewRole)
    }
  }

  const email = useAuthStore(state => state.email)

  const filteredUsers = users.filter(user => {
    const matchesName =
      user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastname.toLowerCase().includes(searchTerm.toLowerCase())

    const matchesRole = selectedRole ? user.role === selectedRole : true

    return matchesName && matchesRole
  })

  return (
    <div className="overflow-x-auto">
      <div className="flex items-center justify-between mb-4">
        <div>
          <label>
            <Trans id="search.by.name">Search:</Trans>
          </label>
          <Input
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="max-w-xs"
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-2" data-testid="User.rol">
              {selectedRole ? (
                <Trans id="role.selected">Role: {i18n._(selectedRole)}</Trans>
              ) : (
                <Trans id="filter.role">Filter by Role</Trans>
              )}
              <ChevronDown className="ml-2 w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => setSelectedRole(null)}>
              <Trans>Clear Role Filter</Trans>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              {Object.entries(USER_ROLE_MESSAGES).map(
                ([roleKey, translatedRole]) => (
                  <DropdownMenuItem
                    key={roleKey}
                    onClick={() => setSelectedRole(roleKey as UserRoleDto)}
                  >
                    {i18n._(translatedRole)}
                  </DropdownMenuItem>
                )
              )}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <p className="mb-2 text-sm text-gray-500">
        {filteredUsers.length} {filteredUsers.length === 1 ? 'user ' : 'users '}
        <Trans>found</Trans>
      </p>
      <table className="min-w-full border border-gray-200">
        <thead>
          <tr>
            <th className="border-b p-4 text-left">
              <Trans>User</Trans>
            </th>
            <th className="border-b p-4 text-left">
              <Trans>Role</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => {
            const isCurrentUser = user.email === email

            return (
              <tr key={user.id} data-testid="User.tableEntry">
                <td className="border-b p-4 flex items-center">
                  <UserAvatar user={user} />
                  <div className="ml-3">
                    <p>
                      {user.firstname + ' ' + user.lastname}{' '}
                      {isCurrentUser && (
                        <Badge data-testid="User.badge">
                          <Trans>You</Trans>
                        </Badge>
                      )}
                    </p>
                    <p
                      className="text-sm text-slate-500"
                      data-testid="User.email"
                    >
                      {user.email}
                    </p>
                  </div>
                </td>
                <td className="border-b p-4">
                  {!isCurrentUser ? (
                    <DropdownMenu modal={false}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          className={'justify-between'}
                          data-testid="User.roleDropdown"
                        >
                          {i18n._(USER_ROLE_MESSAGES[user.role as UserRoleKey])}
                          <ChevronDown className="w-4 h-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuGroup>
                          {Object.entries(USER_ROLE_MESSAGES).map(
                            ([roleKey, translatedRole]) => (
                              <DropdownMenuItem
                                data-testid="User.roleDropdownItem"
                                key={roleKey}
                                onClick={() =>
                                  handleRoleChange(user.id, roleKey)
                                }
                              >
                                {i18n._(translatedRole)}
                              </DropdownMenuItem>
                            )
                          )}
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  ) : (
                    <span>
                      {i18n._(USER_ROLE_MESSAGES[user.role as UserRoleKey])}
                    </span>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
