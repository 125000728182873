import {ApiPaginationOptionsDto} from '@/lib/api/dtos/ApiPaginationOptionsDto.ts'
import {ObservationCreateDto} from '@/lib/observation/dtos/ObservationCreateDto.ts'
import {ObservationDetailDto} from '@/lib/observation/dtos/ObservationDetailDto.ts'
import {ObservationItemDto} from '@/lib/observation/dtos/ObservationItemDto.ts'

import {NestCreateDto} from './dtos/NestCreateDto'
import {NestDetailDto} from './dtos/NestDetailDto'
import {NestItemDto} from './dtos/NestItemDto'
import {NestListOptionsDto} from './dtos/NestListOptionsDto'
import {NestUpdateDto} from './dtos/NestUpdateDto'
import {api} from '../api/api'
import {ApiPaginationResponse} from '../api/dtos/ApiPaginationResponseDto'
import {ApiResponseDto} from '../api/dtos/ApiResponseDto'

export function detail(id: string) {
  return api.get(`api/nests/${id}`).json<ApiResponseDto<NestDetailDto>>()
}

export function all({page = 0, ...args}: NestListOptionsDto) {
  return api
    .get('api/nests', {
      searchParams: {...args, page},
    })
    .json<ApiPaginationResponse<NestItemDto[]>>()
}

export function create(json: NestCreateDto) {
  return api.post('api/nests', {json}).json<ApiResponseDto<NestDetailDto>>()
}

export function update(id: string, json: NestUpdateDto) {
  return api
    .put(`api/nests/${id}`, {json: {...json, id}})
    .json<ApiResponseDto<NestDetailDto>>()
}

export function favorite(id: string) {
  return api
    .post(`api/nests/${id}/favorite`)
    .json<ApiResponseDto<NestDetailDto>>()
}

/**
 * OBSERVATIONS
 */

export function observations(id: string, {page = 0}: ApiPaginationOptionsDto) {
  return api
    .get(`api/nests/${id}/observations`, {
      searchParams: {page},
    })
    .json<ApiResponseDto<ObservationItemDto[]>>()
}

export function observation(id: string) {
  return api
    .get(`api/observations/${id}`)
    .json<ApiResponseDto<ObservationDetailDto>>()
}

export function createObservation(json: ObservationCreateDto) {
  return api
    .post(`api/nests/${json.nestId}/observations`, {json})
    .json<ApiResponseDto<ObservationDetailDto>>()
}

export const nestService = {
  detail,
  all,
  update,
  create,
  createObservation,
  observation,
  observations,
  favorite,
}
