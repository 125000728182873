import {useQuery} from '@tanstack/react-query'
import {Link} from '@tanstack/react-router'
import {FC, memo} from 'react'
import {Marker, Popup} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import {pinIcon} from '@/components/Map/icons/pin'
import {markersQueryOptions} from '@/lib/marker/query'

export const AllNestMarkers: FC = memo(() => {
  // const bounds = useMapBounds()
  // const boundsDebounced = useDebounce(bounds, 500)
  const nestsQuery = useQuery(markersQueryOptions())

  // useEffect(() => {
  //   if (!nestsQuery.data) {
  //     return
  //   }
  //   map.fitBounds(
  //     nestsQuery.data.data.map(nest => [nest.latitude, nest.longitude]),
  //     {padding: [50, 50]}
  //   )
  // }, [map, nestsQuery.data])

  if (!nestsQuery.data) {
    return null
  }

  const nests = nestsQuery.data

  return (
    <MarkerClusterGroup chunkedLoading>
      {nests.map(nest => (
        <Marker
          key={`${nest.id}-all-marker`}
          icon={pinIcon('#C60000')}
          position={[nest.latitude, nest.longitude]}
        >
          <Popup>
            <Link to="/nests/overview/all/$nestId" params={{nestId: nest.id}}>
              {nest.nestCode}
            </Link>
          </Popup>
        </Marker>
      ))}
    </MarkerClusterGroup>
  )
})
