import {Trans} from '@lingui/macro'
import {FC} from 'react'

type Props = {
  error: unknown
}

export const ObservationDetailError: FC<Props> = () => {
  return (
    <div className="flex flex-col items-center flex-grow h-full">
      <div className="py-16 max-w-96">
        <h1 className="text-2xl font-medium text-center">
          <Trans>An error occurred</Trans>
        </h1>
        <p className="text-center text-gray-500">
          <Trans>
            Something went wrong while fetching the nest. Please try again
            later.
          </Trans>
        </p>
      </div>
    </div>
  )
}
