import {t, Trans} from '@lingui/macro'
import {format} from 'date-fns'
import {FC} from 'react'

import {Back} from '@/components/ui/back'
import {Card, CardContent} from '@/components/ui/card.tsx'
import {OBSERVATION_ACTIVITY_MESSAGES} from '@/lib/observation/dtos/ObservationActivityDto.ts'
import {ObservationDetailDto} from '@/lib/observation/dtos/ObservationDetailDto'
import {OBSERVATION_WEATHER_MESSAGES} from '@/lib/observation/dtos/ObservationWeatherDto.ts'

type FromPaths =
  | '/nests/overview/favorites/$nestId'
  | '/nests/overview/all/$nestId'
type Props = {
  observation: ObservationDetailDto
  nestId: string
  from: FromPaths
}

export const ObservationDetail: FC<Props> = ({from, nestId, observation}) => {
  return (
    <>
      <Back params={{nestId: nestId}} to={from} search={{page: 0}} />

      <Card
        style={{viewTransitionName: `observationCard-${observation.id}`}}
        className={'pt-6'}
      >
        <CardContent className={'flex flex-col w-full gap-4'}>
          <div className={'flex flex-row gap-4'}>
            <div>
              <div className={'font-semibold'}>
                <Trans>Date and Time</Trans>
              </div>
              <span data-testid={'ObservationDetail.observedAt'}>
                {format(observation.observedAt, t`dd.MM.yy HH:mm`)}
              </span>
            </div>
            <div>
              <div className={'font-semibold'}>
                <Trans>Author</Trans>
              </div>

              <span>{observation.author}</span>
            </div>
          </div>
          <div className={'flex flex-row gap-4 justify-between'}>
            <div>
              <div className={'font-semibold'}>
                <Trans>Temperature</Trans>
              </div>
              <span>
                {observation.temperature}
                <Trans>&deg; Celsius</Trans>{' '}
              </span>
            </div>
            <div>
              <div className={'font-semibold'}>
                <Trans>Weather</Trans>
              </div>
              <div data-testid={'ObservationDetail.weather'}>
                {t({
                  id: OBSERVATION_WEATHER_MESSAGES[
                    observation.weather ?? 'NONE'
                  ].id,
                  message:
                    OBSERVATION_WEATHER_MESSAGES[observation.weather ?? 'NONE']
                      .message,
                })}
              </div>
            </div>
            <div>
              <div className={'font-semibold'}>
                <Trans>Activity</Trans>
              </div>
              <div data-testid={'ObservationDetail.activity'}>
                {t({
                  id: OBSERVATION_ACTIVITY_MESSAGES[
                    observation.activity ?? 'NONE'
                  ].id,
                  message:
                    OBSERVATION_ACTIVITY_MESSAGES[
                      observation.activity ?? 'NONE'
                    ].message,
                })}
              </div>
            </div>
          </div>
          <div className={'font-semibold'}>
            <Trans>Comment</Trans>
          </div>
          {observation.comment}
        </CardContent>
      </Card>
    </>
  )
}
